import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import translations from "../translations"

import laborImage from "../images/labor_small.jpg"
import microscopeImage from "../images/microscope.png"

export const query = graphql`
query ProductDevelopmentPageQuery {
  projectManagementImage: file(relativePath: { eq: "project_management.jpg" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(
        maxWidth: 1200
        quality: 90
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const ProductDevelopmentPage = ({ data, pathContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title={translations[locale].productDevelopmentPage.header} />
    <div className="page">
      <div className="container center">
        <h2 className="page-title">{translations[locale].productDevelopmentPage.header}</h2>
      </div>
      <div className="container row">
        <p className="half" dangerouslySetInnerHTML={{__html: translations[locale].productDevelopmentPage.firstParagraph}} />
        <div className="half">
          <Img fluid={data.projectManagementImage.childImageSharp.fluid} alt="Project Management" />
        </div>
      </div>
      <div className="container row no-padding center">
        <p className="cite">{translations[locale].productDevelopmentPage.cite}</p>
      </div>
      <div className="container row">
        <p>{translations[locale].productDevelopmentPage.secondParagraph}</p>
      </div>
      <div className="container row">
        <div className="half">
          <img src={laborImage} alt="labor" />
        </div>
        <div className="half">
          <img src={microscopeImage} alt="microscope" />
        </div>
      </div>
    </div>
  </Layout>
)

export default ProductDevelopmentPage
